import { useI18n } from 'vue-i18n';

export function getSectionFieldLabel(baseType: string, sectionFieldName: string) {
  const { t } = useI18n({});

  return t(`sections.${baseType}.sectionFields.${sectionFieldName}`);
}

export function getSectionFieldDescription(baseType: string, sectionFieldName: string) {
  const { t } = useI18n({});

  return t(`sections.${baseType}.sectionFieldsDescriptions.${sectionFieldName}`);
}

export function getSectionFieldPlaceholder(baseType: string, sectionFieldName: string) {
  const { t } = useI18n({});

  const translation = t(`sections.${baseType}.sectionFields.${sectionFieldName}Placeholder`);

  return translation === `sections.${baseType}.sectionFields.${sectionFieldName}Placeholder` ? null : translation;
}
