<script setup lang="ts">
import isEqual from 'lodash/isEqual';
import { useForm } from 'vee-validate';
import { reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string, boolean } from 'yup';

import webAppApi from '@/api/webApp';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { EnvConfig } from '@/types/env-config';
import type { WebApp } from '@/types/web-app';
import type { WebAppTabs } from '@/types/web-app-tabs';

import BaseBadge from './base-badge.vue';
import BaseButton from './base-button.vue';
import BaseInput from './base-input.vue';
import BaseSwitch from './base-switch.vue';
import ThePageHeader from './the-page-header.vue';
import WebAppFeature from './web-app-feature.vue';

interface Props {
  webApp: WebApp;
  envConfig: EnvConfig;
  backPath: string;
  tabs: WebAppTabs;
}

interface WebAppIntegrationsForm extends WebApp {
  envConfigAttributes: EnvConfig;
}

const props = defineProps<Props>();
const { t } = useI18n({});

const webApp = ref<WebApp>(props.webApp);

const webAppSchema = object({
  isMoksysActive: boolean(),
  isHubspotActive: boolean(),
  isGtmActive: boolean(),
  isChatwootActive: boolean(),
  isTypebotActive: boolean(),
  typebotName: string().nullable()
    .label(t('integrations.fields.typebot.typebotName')),
  isRudderStackActive: boolean(),
  performanceChartsUrl: string().url().nullable()
    .label(t('integrations.fields.charts.performanceUrl')),
  isCookiebotActive: boolean(),
  envConfigData: object({
    mokUser: string().label(t('integrations.fields.moksys.user')),
    mokPassword: string().label(t('integrations.fields.moksys.password')),
  }),
});

const initialWebAppData = reactive<Partial<WebAppIntegrationsForm>>({
  isMoksysActive: props.webApp.isMoksysActive,
  isGtmActive: props.webApp.isGtmActive,
  isHubspotActive: props.webApp.isHubspotActive,
  isChatwootActive: props.webApp.isChatwootActive,
  isTypebotActive: props.webApp.isTypebotActive,
  typebotName: props.webApp.typebotName,
  isRudderStackActive: props.webApp.isRudderStackActive,
  performanceChartsUrl: props.webApp.performanceChartsUrl,
  isCookiebotActive: props.webApp.isCookiebotActive,
  envConfigAttributes: props.envConfig,
});

const { resetForm, values: webAppData, setValues: setWebAppData, errors } = useForm<WebAppIntegrationsForm>(
  {
    initialValues: { ...initialWebAppData },
    validationSchema: webAppSchema,
    validateOnMount: true,
  },
);

const disabledActions = computed(() => (isEqual(webAppData, initialWebAppData)));
const canEnableHubspot = computed(() => (!!props.webApp.canEnableHubspot));
const canEnableRudderStack = computed(() => (!!props.webApp.canEnableRudderStack));
const subtitle = computed(() => (isEnabled: boolean) => (
  (isEnabled) ? t('integrations.isEnabled') : t('integrations.isDisabled')
));

const {
  mutate: updateWebAppMutation,
  isLoading: isUpdateLoading,
  isError: isUpdateError,
  isSuccess: isUpdateSuccess,
} = useMutation(
  () => webAppApi.update(props.webApp.id, webAppData, false),
  { onSuccess: (response) => {
    setWebAppData(response.data.webApp);
    Object.assign(initialWebAppData, response.data.webApp);
  } },
);

const VISIBILITY_TIMEOUT = 3000;

const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateSuccess, timeout: VISIBILITY_TIMEOUT, reload: true },
);
const { isVisible: isErrorMessageVisible } = useVisibilityTimeout(
  { condition: isUpdateError, timeout: VISIBILITY_TIMEOUT },
);

function updateWebApp() {
  if (Object.keys(errors.value).length !== 0) return;
  updateWebAppMutation();
}
</script>

<template>
  <the-web-app-layout
    :web-app="webApp"
    selected-tab="integrations"
    :tabs="tabs"
    :back-path="backPath"
  >
    <the-page-header
      :title="$t('integrations.title')"
      :description="$t('integrations.description')"
      emoji="🔑"
    >
      <template #notice>
        <base-badge
          v-if="isErrorMessageVisible"
          :label="$t('common.saveError')"
          color="error"
          theme="dark"
        />
        <base-badge
          v-else-if="isSuccessMessageVisible"
          :label="$t('common.savedSuccessfully')"
          color="success"
          theme="dark"
        />
      </template>
    </the-page-header>
    <form
      id="web-app-edit-form"
    >
      <div class="flex w-full flex-col gap-2 gap-x-8 rounded-lg border border-gray-300 bg-white p-3 text-xs">
        <web-app-feature
          :title="$t('integrations.fields.moksys.title')"
          :description="$t('integrations.fields.moksys.description')"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isMoksysActive"
              name="isMoksysActive"
              disabled
            />
          </template>
          <div class="grid w-full max-w-3xl grid-cols-2 items-stretch gap-x-8 px-3">
            <base-input
              v-model="webAppData.envConfigAttributes.mokUser"
              :placeholder="$t('integrations.fields.moksys.user')"
              :label="$t('integrations.fields.moksys.user')"
              theme="floating-label-input"
              name="envConfigAttributes.mokUser"
              size="xs"
            />
            <base-input
              v-model="webAppData.envConfigAttributes.mokPassword"
              :placeholder="$t('integrations.fields.moksys.password')"
              :label="$t('integrations.fields.moksys.password')"
              theme="floating-label-input"
              name="envConfigAttributes.mokPassword"
              size="xs"
            />
          </div>
        </web-app-feature>
        <web-app-feature
          :title="$t('integrations.fields.hubspot.title')"
          :subtitle="subtitle(webApp.canEnableHubspot)"
          :description="$t('integrations.fields.hubspot.description')"
          :disabled="!canEnableHubspot"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isHubspotActive"
              :disabled="!canEnableHubspot"
              name="isHubspotActive"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          :title="$t('integrations.fields.googleTagManager.title')"
          :subtitle="subtitle(webApp.canEnableGtm)"
          :description="$t('integrations.fields.googleTagManager.description')"
          :disabled="!webApp.canEnableGtm"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isGtmActive"
              :disabled="!webApp.canEnableGtm"
              name="isGtmActive"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          :title="$t('integrations.fields.chatwoot.title')"
          :subtitle="subtitle(webApp.canEnableChatwoot)"
          :description="$t('integrations.fields.chatwoot.description')"
          :disabled="!webApp.canEnableChatwoot"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isChatwootActive"
              :disabled="!webApp.canEnableChatwoot || webAppData.isTypebotActive"
              name="isChatwootActive"
            />
          </template>
        </web-app-feature>
        <web-app-feature
          :title="$t('integrations.fields.typebot.title')"
          :subtitle="$t('integrations.fields.typebot.subtitle')"
          :description="$t('integrations.fields.typebot.description')"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isTypebotActive"
              data-testid="is-typebot-active-switch"
              :disabled="webAppData.isChatwootActive"
              name="isTypebotActive"
            />
          </template>
          <div class="grid w-full max-w-3xl grid-cols-2 items-stretch gap-x-8 px-3">
            <base-input
              v-model="webApp.typebotName"
              data-testid="typebot-name-input"
              :placeholder="$t('integrations.fields.typebot.typebotName')"
              :label="$t('integrations.fields.typebot.typebotName')"
              theme="floating-label-input"
              name="typebotName"
              size="xs"
            />
          </div>
        </web-app-feature>
        <web-app-feature
          :title="$t('integrations.fields.rudderStack.title')"
          :subtitle="subtitle(webApp.canEnableRudderStack)"
          :description="$t('integrations.fields.rudderStack.description')"
          :disabled="!canEnableRudderStack"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isRudderStackActive"
              :disabled="!canEnableRudderStack"
              name="isRudderStackActive"
            />
          </template>
          <div class="grid w-full max-w-3xl grid-cols-2 items-stretch gap-x-8 px-3">
            <base-input
              v-model="webApp.performanceChartsUrl"
              data-testid="performance-charts-input"
              :placeholder="$t('integrations.fields.charts.performanceUrl')"
              :label="$t('integrations.fields.charts.performanceUrl')"
              theme="floating-label-input"
              name="performanceChartsUrl"
              size="xs"
            />
          </div>
        </web-app-feature>
        <web-app-feature
          :title="$t('integrations.fields.cookiebot.title')"
          :subtitle="subtitle(webApp.canEnableCookiebot)"
          :description="$t('integrations.fields.cookiebot.description')"
          :disabled="!webApp.canEnableCookiebot"
        >
          <template #toggle>
            <base-switch
              v-model="webAppData.isCookiebotActive"
              :disabled="!webApp.canEnableCookiebot"
              name="isCookiebotActive"
            />
          </template>
        </web-app-feature>
      </div>
      <div class="mt-4 flex grow justify-end gap-4 text-sm md:text-base">
        <base-button
          theme="secondary"
          :disabled="disabledActions"
          :label="$t('actions.cancel')"
          @click="resetForm"
        />
        <base-button
          type="button"
          theme="primary"
          :loading="isUpdateLoading"
          :label="$t('actions.saveChanges')"
          :disabled="disabledActions"
          @click.prevent="updateWebApp"
        />
      </div>
    </form>
  </the-web-app-layout>
</template>
