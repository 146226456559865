<script setup lang="ts">
import { MokButton, MokInput } from '@mok-labs/components';
import { PhPlus } from '@phosphor-icons/vue';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { reactive, computed, watch } from 'vue';

import { QuestionSectionFieldValue } from '@/types/question-section-field';
import { getSectionFieldLabel } from '@/utils/get-section-field-translations';

type QuestionSectionField = {
  value: QuestionSectionFieldValue;
}

export type Props = {
  name: string;
  baseType: string;
  modelValue?:(QuestionSectionField)[];
}

const MINIMUM_QUESTIONS_COUNT = 3;
const props = defineProps<Props>();
const label = getSectionFieldLabel(props.baseType, props.name);
const emit = defineEmits<{(e: 'update:modelValue', value: QuestionSectionField[]): void }>();

function getInitialQuestions(questions: QuestionSectionField[] | undefined) {
  const initialQuestions = cloneDeep(questions) || [];
  const emptyQuestions = Math.max(MINIMUM_QUESTIONS_COUNT - initialQuestions.length, 0);

  const defaultQuestions = Array.from({ length: emptyQuestions }, () => (
    { value: { title: '', content: '' } }
  ));

  return [...initialQuestions, ...defaultQuestions];
}

const questions = reactive<QuestionSectionField[]>(getInitialQuestions(props.modelValue));
const filledQuestions = computed(() => questions.filter((question) => (
  question.value.title || question.value.content),
));

watch(() => props.modelValue, () => {
  if (!isEqual(props.modelValue, filledQuestions.value)) {
    questions.splice(0, questions.length, ...getInitialQuestions(props.modelValue));
  }
}, { deep: true });

watch(filledQuestions, () => {
  if (!isEqual(props.modelValue, filledQuestions.value)) {
    emit('update:modelValue', filledQuestions.value);
  }
}, { deep: true });

function addQuestion() {
  questions.push({
    value: {
      title: '',
      content: '',
    },
  });
}
</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <p
      class="text-xs font-semibold text-gray-900"
      data-testid="group-title"
    >
      {{ label }}
    </p>
    <div
      v-for="(question, index) in questions"
      :key="index"
      class="flex gap-4"
    >
      <p class="pt-3 text-xs">
        {{ index +1 }}.
      </p>
      <div
        class="grow space-y-4"
        data-testid="question"
      >
        <mok-input
          v-model="question.value.title"
          :name="`question-title-${index}`"
        />
        <mok-input
          v-model="question.value.content"
          :name="`question-content-${index}`"
        />
      </div>
    </div>
    <div class="flex justify-center">
      <mok-button
        type="button"
        variant="secondary"
        rounded-full
        :icon="PhPlus"
        data-testid="add-question-button"
        @click="addQuestion"
      />
    </div>
  </div>
</template>
