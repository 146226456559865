import { serialize } from 'object-to-formdata';

import { WebAppServiceGroup } from '@/types/web-app-service-group';
import { WebAppServiceGroupForm } from '@/types/web-app-service-group-form';

import api from './index';

const BASE_WEB_APP_PATH = '/api/internal/web_apps';
const BASE_PATH = '/api/internal/web_app_service_groups';

export default {
  async index(webAppId: number): Promise<WebAppServiceGroup[]> {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_service_groups`;

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data.webAppServiceGroups as WebAppServiceGroup[];
  },
  create(webAppId: number, webAppServiceGroup: WebAppServiceGroupForm) {
    const path = `${BASE_WEB_APP_PATH}/${webAppId}/web_app_service_groups`;

    return api({
      method: 'post',
      url: path,
      data: serialize({ webAppServiceGroup }),
    });
  },
  update(webAppServiceGroupId: number, webAppServiceGroup: WebAppServiceGroupForm) {
    const path = `${BASE_PATH}/${webAppServiceGroupId}`;

    return api({
      method: 'patch',
      url: path,
      data: serialize({ webAppServiceGroup }),
    });
  },
  delete(webAppServiceGroupId: number) {
    const path = `${BASE_PATH}/${webAppServiceGroupId}`;

    return api({
      method: 'delete',
      url: path,
    });
  },
};
