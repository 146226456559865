<script setup lang="ts">

import { MokInput } from '@mok-labs/components';

import { getSectionFieldLabel, getSectionFieldPlaceholder } from '@/utils/get-section-field-translations';

export type Props = {
  name: string;
  baseType: string;
  modelValue?: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{(e: 'update:modelValue', value: string | undefined): void}>();
const label = getSectionFieldLabel(props.baseType, props.name);
const placeholder = getSectionFieldPlaceholder(props.baseType, props.name);
function handleChange(value: string | undefined) {
  emit('update:modelValue', value);
}

</script>

<template>
  <div class="space-y-3 rounded-lg border border-gray-300 p-5">
    <p class="text-base font-semibold text-gray-900">
      {{ label }}
    </p>
    <mok-input
      :model-value="modelValue"
      :name="name"
      :placeholder="placeholder || label"
      @update:model-value="handleChange"
    />
  </div>
</template>
