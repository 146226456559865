import { camelize } from 'humps';
import { type Component } from 'vue';

import SectionsBooleanSectionField from '@/components/sections/boolean-section-field.vue';
import SectionsColorSectionField from '@/components/sections/color-section-field.vue';
import SectionsExtendedColorSectionField from '@/components/sections/extended-color-section-field.vue';
import SectionsImageSectionField from '@/components/sections/image-section-field.vue';
import SectionsLogoSectionField from '@/components/sections/logo-section-field.vue';
import SectionsQuestionSectionFieldGroup from '@/components/sections/question-section-field-group.vue';
import SectionsRichTextSectionField from '@/components/sections/rich-text-section-field.vue';
import SectionsTextSectionField from '@/components/sections/text-section-field.vue';
import type { SectionFieldSchema } from '@/types/sections/section-field-schema';

type ComponentName = 'imageSectionField' | 'textSectionField' |
'richTextSectionField' | 'colorSectionField' |
'booleanSectionField' | 'extendedColorSectionField' |
'logoSectionField' | 'questionSectionField';

type ComponentMap = {
  [key in ComponentName]: Component | undefined;
};

function getSectionFieldComponent(sectionFieldSchema: SectionFieldSchema) {
  const singleComponents : ComponentMap = {
    imageSectionField: SectionsImageSectionField,
    textSectionField: SectionsTextSectionField,
    richTextSectionField: SectionsRichTextSectionField,
    colorSectionField: SectionsColorSectionField,
    booleanSectionField: SectionsBooleanSectionField,
    extendedColorSectionField: SectionsExtendedColorSectionField,
    logoSectionField: SectionsLogoSectionField,
    questionSectionField: undefined,
  };

  const groupedComponents : ComponentMap = {
    imageSectionField: undefined,
    textSectionField: undefined,
    richTextSectionField: undefined,
    colorSectionField: undefined,
    booleanSectionField: undefined,
    extendedColorSectionField: undefined,
    logoSectionField: undefined,
    questionSectionField: SectionsQuestionSectionFieldGroup,
  };

  const sectionFieldType : keyof typeof singleComponents = camelize(sectionFieldSchema.component);
  const singleComponent = singleComponents[sectionFieldType];
  const groupComponent = groupedComponents[sectionFieldType];

  return sectionFieldSchema.isGroupedComponent ? groupComponent : singleComponent;
}

export default getSectionFieldComponent;
