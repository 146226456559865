<script setup lang="ts">
import { MokButton } from '@mok-labs/components';
import { PhWarning, PhPlus, PhPlusCircle } from '@phosphor-icons/vue';
import { computed, inject, ref } from 'vue';
import { useMutation } from 'vue-query';

import webAppServiceGroupApi from '@/api/webAppServiceGroup';
import { usePermissions } from '@/composables/usePermissions';
import type { Permissions } from '@/types/permissions';
import { WebApp } from '@/types/web-app';
import { WebAppService } from '@/types/web-app-service';
import { WebAppServiceGroup } from '@/types/web-app-service-group';
import { WebAppServiceGroupWithServices } from '@/types/web-app-service-group-with-services';
import TrelloSvg from '@assets/images/trello.svg';

import WebAppServicesGroupFormModal from './web-app-services-group-form-modal.vue';
import WebAppServicesIndexGroupsColumn from './web-app-services-index-groups-column.vue';

interface Props {
  webApp: WebApp;
  webAppServiceGroupsWithServices: WebAppServiceGroupWithServices[];
  webAppServiceGroupsNeedImageAndDescription: boolean;
  draggableTarget?: HTMLElement;
}
const props = defineProps<Props>();

interface Emits {
  (e: 'savedGroup', successMode: 'edit' | 'create'): void;
  (e: 'deletedGroup'): void;
  (e: 'editService', service: WebAppService): void;
  (e: 'deleteService', service: WebAppService): void;
  (e: 'movedService'): void;
  (e: 'update:draggableTarget', draggableTarget?: HTMLElement): void;
}

const emits = defineEmits<Emits>();

const { resourcePermissions: webAppServiceGroupPermissions } = usePermissions(
  'webAppServiceGroups', inject<Permissions>('permissions'),
);

const noGroups = computed(() => props.webAppServiceGroupsWithServices.length === 0);

const visibleGroupForm = ref(false);
const selectedGroup = ref<WebAppServiceGroup>();
function openWebAppServiceGroupModal() {
  visibleGroupForm.value = true;
}
function editWebAppServiceGroup(webAppServiceGroup: WebAppServiceGroup) {
  selectedGroup.value = webAppServiceGroup;
  openWebAppServiceGroupModal();
}
function closeWebAppServiceGroupModal() {
  visibleGroupForm.value = false;
  selectedGroup.value = undefined;
}
function webAppServiceGroupSaveSuccess() {
  const successMode = selectedGroup.value ? 'edit' : 'create';
  closeWebAppServiceGroupModal();
  emits('savedGroup', successMode);
}

const {
  mutate: deleteGroupModal,
} = useMutation(
  (groupId: number) => webAppServiceGroupApi.delete(groupId),
  { onSuccess: () => emits('deletedGroup') },
);
const deleteGroupModalOpen = ref(false);
function openDeleteGroupModal(webAppServiceGroup: WebAppServiceGroup) {
  selectedGroup.value = webAppServiceGroup;
  deleteGroupModalOpen.value = true;
}
function cancelDeleteGroup() {
  deleteGroupModalOpen.value = false;
}
function deleteGroup() {
  if (selectedGroup.value) {
    deleteGroupModal(selectedGroup.value.id);
  }
  deleteGroupModalOpen.value = false;
  selectedGroup.value = undefined;
}

function editService(webAppService: WebAppService) {
  emits('editService', webAppService);
}
function deleteService(webAppService: WebAppService) {
  emits('deleteService', webAppService);
}
function movedService() {
  emits('movedService');
}

function emitTarget(event?: HTMLElement) {
  emits('update:draggableTarget', event);
}
</script>

<template>
  <div class="mb-6 space-x-8">
    <span class="font-semibold text-gray-900">
      {{ $t('webAppServices.index.groupTitle') }}
    </span>
    <mok-button
      v-if="!noGroups && webAppServiceGroupPermissions.create"
      data-testid="add-group-button"
      variant="secondary"
      :icon="PhPlus"
      icon-position="left"
      :label="$t('webAppServices.index.addGroupButton')"
      size="medium"
      class="ml-8"
      @click="openWebAppServiceGroupModal"
    />
  </div>
  <span
    class="text-sm text-gray-900"
    :class="noGroups ? 'mb-16' : 'mb-8'"
  >
    {{ $t('webAppServices.index.groupSubtitle') }}
  </span>

  <div
    v-if="noGroups"
    class="mx-auto flex w-52 flex-col items-center justify-center text-center"
  >
    <div class="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-primary-100">
      <component
        :is="TrelloSvg"
        class="h-10 w-10 stroke-primary-700"
      />
    </div>
    <p class="mb-4 font-semibold leading-8 text-black">
      {{ $t('webAppServices.index.noGroups') }}
    </p>
    <p class="mb-6 text-sm leading-6 text-gray-500">
      {{ $t('webAppServices.index.createFirstGroup') }}
    </p>
    <mok-button
      v-if="webAppServiceGroupPermissions.create"
      data-testid="add-first-group-button"
      variant="primary"
      :icon="PhPlusCircle"
      icon-position="left"
      :label="$t('webAppServices.index.addGroupButton')"
      size="medium"
      full-width
      @click="openWebAppServiceGroupModal"
    />
  </div>
  <div
    v-else
  >
    <div
      class="flex max-w-full gap-8 overflow-x-auto pb-6"
    >
      <web-app-services-index-groups-column
        v-for="group in props.webAppServiceGroupsWithServices"
        :key="group.id"
        :draggable-target="draggableTarget"
        class="w-60 shrink-0 self-start"
        :group="group"
        @edit="() => editWebAppServiceGroup(group)"
        @delete="() => openDeleteGroupModal(group)"
        @edit-service="editService"
        @delete-service="deleteService"
        @moved-service="movedService"
        @update:draggable-target="emitTarget"
      />
    </div>
  </div>
  <web-app-services-group-form-modal
    v-if="visibleGroupForm"
    :web-app-service-group="selectedGroup"
    :open="visibleGroupForm"
    :web-app-id="webApp.id"
    :web-app-service-groups-need-image-and-description="webAppServiceGroupsNeedImageAndDescription"
    @save="webAppServiceGroupSaveSuccess"
    @close="closeWebAppServiceGroupModal"
  />
  <base-mok-alert-modal
    :is-open="deleteGroupModalOpen"
    icon-color="warning"
    :icon="PhWarning"
    :title="$t('webAppServices.index.deleteGroupModal.title')"
    :confirm-label="$t('actions.delete')"
    :cancel-label="$t('actions.cancel')"
    @confirm="deleteGroup"
    @cancel="cancelDeleteGroup"
    @close="cancelDeleteGroup"
  >
    <p> {{ $t('webAppServices.index.deleteGroupModal.description') }} </p>
  </base-mok-alert-modal>
</template>
