<script setup lang="ts">
import { MokButton, MokDropdown, MokIcon, MokInput } from '@mok-labs/components';
import { PhWarning } from '@phosphor-icons/vue';
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { number, object, string } from 'yup';

import webAppApi from '@/api/webApp';
import { Country } from '@/types/country';
import { Sponsor } from '@/types/sponsor';
import { WebAppForm } from '@/types/web-app-form';
import regexPatterns from '@/values/regex-patterns';

import BaseRadioInputGroup from './base-radio-input-group.vue';

const props = defineProps<{
  backPath: string;
  sponsors: Sponsor[];
  countries: Country[];
  salesSponsorId: number;
  countriesWithAvailableSalesHerokuApps: Country[];
}>();

const { t } = useI18n({});

const webAppTypes = [
  { value: 'productive', label: t('webApp.fields.appTypes.productive') },
  { value: 'sales', label: t('webApp.fields.appTypes.sales') },
];

const webApp = reactive<WebAppForm>({
  sponsorId: undefined,
  contractId: undefined,
  countryId: undefined,
  appType: 'productive',
  name: undefined,
  infrastructureType: 'heroku_public',
  herokuAppName: undefined,
  envConfigAttributes: {
    mokUser: undefined,
    mokPassword: undefined,
    adminUserEmail: undefined,
    adminUserPassword: undefined,
    sidekiqAdminPassword: undefined,
  },
});

const infrastructureTypes: { name: string; id: 'heroku_public' | 'heroku_private' | 'on_premises' }[] = [
  { name: t('webApp.fields.infrastructureTypes.herokuPublic'), id: 'heroku_public' },
  { name: t('webApp.fields.infrastructureTypes.herokuPrivate'), id: 'heroku_private' },
  { name: t('webApp.fields.infrastructureTypes.onPremises'), id: 'on_premises' },
];

const MAX_HEROKU_APP_NAME_LENGTH = 19;

const isHeroku = computed(() => ['heroku_public', 'heroku_private'].includes(webApp.infrastructureType || ''));

const webAppSchema = object({
  sponsorId: string().required().label(t('webApp.fields.sponsorId')),
  contractId: number().typeError(t('validates.number', { field: t('webApp.fields.contractId') }))
    .label(t('webApp.fields.contractId')),
  countryId: string().required().label(t('webApp.fields.countryId')),
  name: string().required().label(t('webApp.fields.name')),
  infrastructureType: string().required().label(t('webApp.fields.infrastructureType')),
  herokuAppName: string()
    .max(MAX_HEROKU_APP_NAME_LENGTH)
    .when(['infrastructureType', 'appType'], {
      is: (_: string, appType: string) => isHeroku.value && appType === 'productive',
      then: () => string().matches(
        regexPatterns.kebabCaseWithNumbers,
        t('webApp.errors.herokuAppNameFormat'),
      ).required(),
    })
    .label(t('webApp.fields.herokuAppName')),
  appType: string()
    .required()
    .oneOf(['productive', 'sales'])
    .label(t('webApp.fields.appType')),
  envConfig: object()
    .when('appType', {
      is: 'productive',
      then: (schema) => schema.shape({
        mokUser: string().required().label(t('envConfig.fields.mokUser')),
        mokPassword: string().required().label(t('envConfig.fields.mokPassword')),
        adminUserEmail: string().required().label(t('envConfig.fields.adminUserEmail')),
        adminUserPassword: string().required().label(t('envConfig.fields.adminUserPassword')),
        sidekiqAdminPassword: string().required().label(t('envConfig.fields.sidekiqAdminPassword')),
      }),
      otherwise: (schema) => schema.shape({}),
    }),
});

const {
  mutate: createWebAppMutation,
  isLoading,
  isError,
} = useMutation(
  () => webAppApi.create(webApp),
  { onSuccess: () => { window.location.href = props.backPath; } },
);

const isSalesApp = computed(() => webApp.appType === 'sales');

const availableCountries = computed(() => (
  isSalesApp.value ? props.countriesWithAvailableSalesHerokuApps : props.countries),
);

const areAllSalesAppsUnavailable = computed(() => props.countriesWithAvailableSalesHerokuApps.length === 0);

const showNoAvailableSalesAppsWarning = computed(() => isSalesApp.value && areAllSalesAppsUnavailable.value);

function clearEnvConfigAttributes() {
  webApp.envConfigAttributes.mokUser = undefined;
  webApp.envConfigAttributes.mokPassword = undefined;
  webApp.envConfigAttributes.adminUserEmail = undefined;
  webApp.envConfigAttributes.adminUserPassword = undefined;
  webApp.envConfigAttributes.sidekiqAdminPassword = undefined;
}

watch(isSalesApp, () => {
  if (isSalesApp.value) {
    webApp.herokuAppName = undefined;
    webApp.countryId = undefined;
    webApp.infrastructureType = 'heroku_public';
    clearEnvConfigAttributes();

    if (!webApp.sponsorId) {
      webApp.sponsorId = props.salesSponsorId;
    }
  }
});

</script>
<template>
  <v-form
    id="web-app-form"
    class="flex w-full flex-col gap-7"
    :validation-schema="webAppSchema"
    validate-on-mount
    @submit="createWebAppMutation"
  >
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-3 flex flex-col gap-2 rounded-lg border border-gray-200 p-6">
        <h3 class="text-sm font-semibold">
          {{ $t("webApp.titles.typeSection") }}
        </h3>
        <base-radio-input-group
          v-model="webApp.appType"
          :options="webAppTypes"
          name="appType"
        />
      </div>
      <div class="col-span-9 flex flex-col gap-2 rounded-lg border border-gray-200 p-6">
        <h3
          class="text-sm font-semibold"
          :class="{ 'text-gray-300': isSalesApp }"
        >
          {{ $t('webApp.titles.infrastructureSection') }}
        </h3>
        <base-button-group
          v-model="webApp.infrastructureType"
          :options="infrastructureTypes"
          name="infrastructureType"
          :disabled="isSalesApp"
        />
      </div>
    </div>
    <div class="col-span-12 flex flex-col gap-2 rounded-lg border border-gray-200 px-6 py-8">
      <h3 class="text-sm font-semibold">
        {{ $t('webApp.titles.generalSection') }}
      </h3>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <mok-dropdown
          v-model="webApp.sponsorId"
          class="w-full"
          :options="sponsors"
          :placeholder="$t('webApp.fields.sponsorId')"
          name="sponsorId"
          model-key="id"
          :disabled="showNoAvailableSalesAppsWarning"
        />
        <mok-dropdown
          v-model="webApp.countryId"
          class="w-full"
          :options="availableCountries"
          :placeholder="$t('webApp.fields.countryId')"
          name="countryId"
          model-key="id"
          :disabled="showNoAvailableSalesAppsWarning"
        />
        <mok-input
          v-model="webApp.name"
          :placeholder="$t('webApp.fields.name')"
          name="name"
          :disabled="showNoAvailableSalesAppsWarning"
        />
        <mok-input
          v-model="webApp.contractId"
          :disabled="isSalesApp"
          :placeholder="$t('webApp.fields.contractId')"
          name="contractId"
        />
        <mok-input
          v-if="!isSalesApp"
          v-model="webApp.herokuAppName"
          :placeholder="$t('webApp.fields.herokuAppName')"
          name="herokuAppName"
          :maxlength="20"
          :helper-text="$t('webApp.fields.herokuAppNameHelperText')"
        />
      </div>
    </div>
    <div class="col-span-12 flex flex-col gap-2 rounded-lg border border-gray-200 px-6 py-8">
      <h3
        class="text-sm font-semibold"
        :class="{ 'text-gray-300': isSalesApp }"
      >
        {{ $t('webApp.titles.integrationsSection') }}
      </h3>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <mok-input
          v-model="webApp.envConfigAttributes.mokUser"
          :placeholder="$t('envConfig.fields.mokUser')"
          name="envConfig.mokUser"
          :disabled="isSalesApp"
        />
        <mok-input
          v-model="webApp.envConfigAttributes.mokPassword"
          class="border-transparent focus:border-transparent focus:ring-0"
          :placeholder="$t('envConfig.fields.mokPassword')"
          name="envConfig.mokPassword"
          :disabled="isSalesApp"
          type="password"
        />
        <mok-input
          v-model="webApp.envConfigAttributes.adminUserEmail"
          :placeholder="$t('envConfig.fields.adminUserEmail')"
          name="envConfig.adminUserEmail"
          :disabled="isSalesApp"
        />
        <mok-input
          v-model="webApp.envConfigAttributes.adminUserPassword"
          :placeholder="$t('envConfig.fields.adminUserPassword')"
          name="envConfig.adminUserPassword"
          :disabled="isSalesApp"
          type="password"
        />
        <mok-input
          v-model="webApp.envConfigAttributes.sidekiqAdminPassword"
          :placeholder="$t('envConfig.fields.sidekiqAdminPassword')"
          name="envConfig.sidekiqAdminPassword"
          :disabled="isSalesApp"
          type="password"
          class="focus:ring-0"
        />
      </div>
    </div>
    <div
      v-if="showNoAvailableSalesAppsWarning"
      class="flex items-center gap-2 self-start rounded-full bg-warning-50 p-1 pr-2.5 text-xs text-warning-700"
    >
      <mok-icon
        :icon="PhWarning"
        size="sm"
        color="warning"
        class="bg-white"
      />
      {{ $t('webApp.errors.noAvailableSalesApps') }}
    </div>
    <div class="col-span-12 flex justify-end gap-4">
      <mok-button
        :href="props.backPath"
        variant="secondary"
        :label="$t('actions.cancel')"
      />
      <mok-button
        data-testid="save-button"
        type="submit"
        :loading="isLoading"
        :disabled="isError || showNoAvailableSalesAppsWarning"
        :label="$t('actions.saveChanges')"
      />
    </div>
  </v-form>
</template>
